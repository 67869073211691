import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

const GMap = withScriptjs(withGoogleMap((props) => {
  return <GoogleMap
    defaultZoom={17}
    defaultCenter={{ lat: 52.22356427049015, lng: 20.956382650000002 }}
  >
    {props.isMarkerShown && <Marker position={{ lat: 52.22356427049015, lng: 20.956382650000002 }}/>}
  </GoogleMap>;
}));

export default GMap;
