import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Fade } from 'react-reveal';
import content from '../../../../content/sections/jumbotron.yml';
import menu from '../../../../content/settings/menu.yml';

import styles from './jumbotron.module.scss';

const Jumbotron = () => {
  const backgroundStyle = {
    backgroundImage: `url(${content.image})`,
  };

  return <div className={styles.jumbotron} style={backgroundStyle}>
    <div className={`container`}>
      <div className={styles.title}>
        <Fade bottom>
          <ReactMarkdown>
            {content.title}
          </ReactMarkdown>
        </Fade>
      </div>
      <div className={styles.subtitle}>
        <Fade bottom>
          <ReactMarkdown>
            {content.subtitle}
          </ReactMarkdown>
        </Fade>
      </div>

      <Fade bottom cascade>
        <ul className={styles.list}>

          {menu.informations.map((service, index) => (
            <li key={index} className={styles.item}>
              {service.name}
              <a href={`tel:${service.tel.split(' ').join('')}`} className={styles.link}>
                {service.tel}
              </a>
            </li>
          ))}

        </ul>
      </Fade>

      <Fade bottom>
        <p className={styles.buttons}>
          <a href={content.yellow_button_link} target="_blank" rel="noreferrer" className={styles.buttonAccent}>{content.yellow_button_text}</a>
          <a href={`mailto:${content.button_link}`} className={styles.button}>{content.button_text}</a>
        </p>
      </Fade>
    </div>
  </div>;
};

export default Jumbotron;
