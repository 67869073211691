import React, { useEffect, useState } from 'react';
import Flickity from 'react-flickity-component';

import styles from './carousel.module.scss';

const Carousel = ({ slides }) => {
  const flickityOptions = {
    wrapAround: true,
    autoplay: true,
    pageDots: false,
    prevNextButtons: false,
    draggable: false,
    accessibility: false,
  };
  const [flickity, setFlickity] = useState(null);
  useEffect(() => {
    if (flickity !== null && flickity !== undefined) {
      setTimeout(() => {
        flickity.unpausePlayer();
        flickity.playPlayer();
      }, 10);
    }
  }, [flickity]);

  return <div className={styles.carousel}>
    <Flickity reloadOnUpdate={true} options={flickityOptions} static={true} flickityRef={c => setFlickity(c)}>

      {slides.map((slide, index) => (
        <div key={index} className={styles.slide} style={{ backgroundImage: `url(${slide.image})` }}/>
      ))}

    </Flickity>
  </div>;
};

export default Carousel;
