import cl from 'classnames';
import React from 'react';
import { Link } from 'react-scroll';
import menu from '../../../content/settings/menu.yml';

import styles from './mobile-nav.module.scss';
import navStyles from './navbar.module.scss';

const MobileNav = ({ active, closeClick }) => {
  const handleCloseClick = e => {
    e.preventDefault();
    closeClick();
    console.log('as');
  };

  return <div className={cl(styles.container, {
    [styles.containerActive]: active,
  })}>
    <button className={styles.hamburger} onClick={handleCloseClick}>
      <img src="/assets/close-icon.svg" alt="Zamknij menu"/>
    </button>

    <div className={styles.beam}>
      <ul className={navStyles.beamList}>

        {menu.informations.map((service, index) => (
          <li key={index} className={navStyles.beamItem}>
            {service.name}
            <a href={`tel:${service.tel.split(' ').join('')}`} className={navStyles.beamLink}>
              {service.tel}
            </a>
          </li>
        ))}

      </ul>
    </div>

    <div className={styles.menu}>
      <ul className={styles.menuList}>

        {menu.items.map((item, index) => (
          <li key={index} className={navStyles.menuItem}>
            <Link to={item.anchor} activeClass={navStyles.menuLinkActive} offset={-80} duration={750} spy={true} smooth={true} onClick={handleCloseClick} className={navStyles.menuLink}>
              {item.name}
            </Link>
          </li>
        ))}

        <li className={navStyles.menuItem}>
          <a href={'http://komis.staszowski.pl/'} target={'_blank'} tabIndex={0} className={navStyles.menuLink}>
            {menu.dealer}
          </a>
        </li>

      </ul>
    </div>
  </div>;
};

export default MobileNav;
