import React from 'react';
import ReactMarkdown from 'react-markdown';
import content from '../../../../content/sections/door-to-door.yml';

import styles from './door-to-door.module.scss';

const DoorToDoor = () => {
  return <div className={styles.doorToDoor}>
    <div className={`container`}>
      <div className={`section`}>
        <h2 className={`${styles.title}`}>
          {content.title}
        </h2>

        <div className={styles.services}>
          <div className={`row`}>

            {content.services.map((service, index) => (
              <div key={index} className={`col-4 col-xl-2 ${styles.iconWrapper}`}>
                <img src={service.icon} alt={service.title} className={styles.icon}/>
                <div className={styles.name}>
                  <ReactMarkdown>
                    {service.title}
                  </ReactMarkdown>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>
    </div>
  </div>;
};

export default DoorToDoor;
