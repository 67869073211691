import React from 'react';
import ReactMarkdown from 'react-markdown';
import content from '../../../../content/sections/offer.yml';

import styles from './offer.module.scss';

const Offer = () => {
  return <div className={styles.offer}>
    <div className={`container`}>
      <div className={`section`}>
        <div className={styles.items}>
          <div className={`row`}>

            {content.offers.map((offer, index) => (
              <div key={index} className={`col-12 col-xl-4`}>
                <div className={styles.number}>{index + 1}</div>
                <h2 className={styles.title}>{offer.title}</h2>
                <div className={styles.text}>
                  <ReactMarkdown>
                    {offer.text}
                  </ReactMarkdown>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>
    </div>
  </div>;
};

export default Offer;
