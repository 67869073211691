import React, { useEffect, useState } from 'react';
import Navbar from '../../navbar/navbar.js';
import Banner from '../../sections/banner/banner.js';
import Clients from '../../sections/clients/clients.js';
import Contact from '../../sections/contact/contact.js';
import DoorToDoor from '../../sections/door-to-door/door-to-door.js';
import Handling from '../../sections/handling/handling.js';
import Jumbotron from '../../sections/jumbotron/jumbotron.js';
import Offer from '../../sections/offer/offer.js';
import Replacement from '../../sections/replacement/replacement.js';
import Reviews from '../../sections/reviews/reviews.js';
import Service from '../../sections/service/service.js';
import cl from 'classnames';
import { Element } from 'react-scroll';

import styles from './home.module.scss';

const Home = () => {
  const [revealed, setRevealed] = useState(false);
  useEffect(() => {
    setRevealed(true);
  }, []);

  return <div className={cl(styles.container, {
    [styles.containerReveal]: revealed,
  })}>
    <Navbar/>
    <Jumbotron/>

    <Element name={`clients`}>
      <Clients/>
    </Element>

    <Element name={`service`}>
      <Service/>
    </Element>

    <Element name={`door-to-door`}>
      <DoorToDoor/>
      <Banner/>
      <Offer/>
      <Replacement/>
    </Element>

    <Element name={`handling`}>
      <Handling/>
      <Reviews/>
    </Element>

    <Element name={`contact`}>
      <Contact/>
    </Element>
  </div>;
};

export default Home;
