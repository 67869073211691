import React from 'react';
import { Helmet } from 'react-helmet';
import { ParallaxProvider } from 'react-scroll-parallax';
import Home from '../components/pages/home/home.js';
import metadata from '../../content/settings/metadata.yml';
import config from 'react-reveal/globals';

import '../styles/core.scss';

export default () => {
  config({ ssrReveal: true });

  return <>
    <Helmet>
      <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap" rel="stylesheet"/>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description}/>

      <meta property="og:title" content={metadata.title}/>
      <meta property="og:description" content={metadata.description}/>
      <meta property="og:image" content={metadata.og_image}/>
      <meta property="og:locale" content="pl_PL"/>
      <meta property="og:type" content="website"/>
    </Helmet>

    <ParallaxProvider>
      <Home/>
    </ParallaxProvider>
  </>;
}
