import React from 'react';
import cl from 'classnames';
import Flickity from 'react-flickity-component';
import content from '../../../../content/sections/opinions.yml';

import styles from './reviews.module.scss';

const Reviews = () => {
  const flickityOptions = {
    wrapAround: true,
    autoplay: true,
    arrowShape: 'M27.9,82.61A2.08,2.08,0,1,0,31,79.87L6.68,52.08H97.92a2.08,2.08,0,1,0,0-4.16H6.68L31,20.13a2.08,2.08,0,1,0-3.13-2.74L.52,48.63a2.07,2.07,0,0,0,0,2.74Z'
  };

  return <div className={styles.reviews}>
    <div className={`section reviews`}>
      <div className={`container ${styles.container}`}>
        <h2 className={styles.title}>{content.title}</h2>

        <p className={styles.text}>
          <a href={content.button_link} className={styles.button}>{content.button_text}</a>
        </p>

        <div className={styles.wrapper}>
          <Flickity options={flickityOptions} reloadOnUpdate={true} className={styles.cards}>

            {content.reviews.map((review, index) => (
              <div key={index} className={styles.card}>
                <div className={styles.cardHeader}>
                  <div className={styles.cardAvatar}>
                    <img src={review.image} alt={review.name}/>
                  </div>

                  <div className={styles.cardInfo}>
                    <h3 className={styles.cardTitle}>{review.name}</h3>
                    <div className={styles.cardStars}>

                      {[...Array(5)].map((number, index) => (
                        <img key={index} src={content.star} alt="Gwiazdka" className={cl(styles.cardStar, {
                          [styles.cardStarDisabled]: index >= parseInt(review.stars),
                        })}/>
                      ))}

                    </div>
                  </div>
                </div>

                <p className={styles.cardText}>{review.text}</p>
              </div>
            ))}

          </Flickity>
        </div>
      </div>
    </div>
  </div>;
};

export default Reviews;
