import React from 'react';
import ReactMarkdown from 'react-markdown';
import content from '../../../../content/sections/clients.yml';

import styles from './clients.module.scss';

const Clients = () => {
  return <div className={styles.clients}>
    <div className={`container`}>
      <div className={`section`}>
        <h2 className={`${styles.title}`}>
          {content.title}
        </h2>

        <div className={`row`}>
          <div className={`col-12 col-xl-6`}>
            <div className={styles.text}>
              <ReactMarkdown>
                {content.text}
              </ReactMarkdown>
            </div>
          </div>

          <div className={`col-12 col-xl-6`}>
            <ul className={styles.list}>

              {content.clients.map((client, index) => (
                <li key={index} className={styles.item}>
                  <img src={client.image} alt={client.name} className={styles.logo}/>
                </li>
              ))}

            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>;
};

export default Clients;
