import React from 'react';
import ReactMarkdown from 'react-markdown';
import content from '../../../../content/sections/contact.yml';
import GMap from '../../gmap/gmap.js';

import styles from './contact.module.scss';

const Contact = () => {
  return <div className={styles.contact}>
    <div className={`container`}>
      <div className={`section ${styles.section}`}>
        <h2 className={styles.title}>
          {content.title}
        </h2>

        <div className={`row`}>
          <div className={`col-12 col-xl-3 order-1 order-xl-0`}>

            {content.addresses.map((address, index) => (
              <div key={index}>
                <h3 className={styles.subtitle}>
                  {address.title}
                </h3>
                <ul className={styles.list}>
                  <li className={styles.item}>
                    <a href={`mailto:${address.email}`} className={styles.link}>
                      {address.email}
                    </a>
                  </li>
                  <li className={styles.item}>
                    <a href={`tel:${address.tel.split(' ').join('')}`} className={styles.link}>
                      {address.tel}
                    </a>
                  </li>
                </ul>
              </div>
            ))}

            <p className={styles.buttons}>
              <a href={content.button_link} className={styles.button}>
                {content.button_text}
              </a>
            </p>
          </div>

          <div className={`col-12 col-xl-8 offset-xl-1 order-0 order-xl-1 ${styles.column}`}>
            <div className={styles.map}>
              <div className={styles.beam}>
                <h3 className={styles.beamTitle}>
                  {content.beam_title}
                </h3>
                <div className={styles.beamText}>
                  <ReactMarkdown>
                    {content.beam_text}
                  </ReactMarkdown>
                </div>
              </div>

              <GMap isMarkerShown
                googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.GATSBY_MAP_API_KEY}`}
                loadingElement={<div style={{ height: `100%` }}/>}
                containerElement={<div style={{ height: `600px` }}/>}
                mapElement={<div style={{ height: `100%` }}/>}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>;
};

export default Contact;
