import React from 'react';
import { ParallaxBanner } from 'react-scroll-parallax';
import content from '../../../../content/sections/banner.yml';

import styles from './banner.module.scss';

const Banner = () => {
  return <ParallaxBanner className={styles.banner} layers={[{
    image: content.image,
    amount: .3,
  }]}/>;
};

export default Banner;
