import React from 'react';
import ReactMarkdown from 'react-markdown';
import content from '../../../../content/sections/replacement.yml';

import styles from './replacement.module.scss';

const Replacement = () => {
  return <div className={styles.replacement}>
    <div className={`section dark`}>
      <div className={`container`}>
        <h2 className={styles.title}>
          {content.title}
        </h2>
        <img src={content.icon} alt={`Ikonka`} className={styles.image}/>

        <div className={styles.text}>
          <ReactMarkdown>
            {content.text}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  </div>;
};

export default Replacement;
