import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Parallax } from 'react-scroll-parallax';
import content from '../../../../content/sections/service.yml';
import Carousel from '../../carousel/carousel.js';

import styles from './service.module.scss';

const Service = () => {
  return <div className={styles.service}>
    <div className={styles.background}/>

    <div className={`container`}>
      <div className={`row no-gutters`}>
        <div className={`col-12 col-xl-5`}>
          <div className={`section`}>
            <h2 className={styles.title}>
              {content.title}
            </h2>
            <div className={styles.text}>
              <ReactMarkdown>
                {content.text}
              </ReactMarkdown>
            </div>
            <div className={styles.addition}>
              <ReactMarkdown>
                {content.addition}
              </ReactMarkdown>
            </div>
          </div>
        </div>

        <div className={`col-12 col-xl-6 offset-xl-1`}>
          <div className={styles.imageWrapper}>
            <div className={styles.image}>
              <Parallax y={[-20, 20]}>
                <Carousel slides={content.slides}/>
              </Parallax>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>;
};

export default Service;
