import React, { useState } from 'react';
import { Fade } from 'react-reveal';
import { Link, animateScroll } from 'react-scroll';
import menu from '../../../content/settings/menu.yml';
import metadata from '../../../content/settings/metadata.yml';
import MobileNav from './mobile-nav.js';

import styles from './navbar.module.scss';

const Navbar = () => {
  const [isMobileNavActive, setMobileNavActive] = useState(false);
  const handleHamburgerClick = e => {
    e.preventDefault();
    setMobileNavActive(true);
  };

  const handleHamburgerCloseClick = () => {
    setMobileNavActive(false);
  };

  return <>
    <div className={styles.navbar}>
      <div className={`container`}>
        <div className={styles.wrapper}>
          <div className={styles.brand}>
            <h1 className={styles.title}>
              {metadata.title}
            </h1>

            <a onClick={animateScroll.scrollToTop} className={styles.home} tabIndex={0}>
              <Fade top>
                <img src={menu.logo} alt="Staszowski" className={styles.logo}/>
              </Fade>
            </a>
          </div>

          <div className={styles.menu}>
            <Fade top>
              <ul className={styles.menuList}>

                {menu.items.map((item, index) => (
                  <li key={index} className={styles.menuItem}>
                    <Link href={'#'} tabIndex={0} to={item.anchor} activeClass={styles.menuLinkActive} offset={-80} duration={750} spy={true} smooth={true} className={styles.menuLink}>
                      {item.name}
                    </Link>
                  </li>
                ))}

                <li className={styles.menuItem}>
                  <a href={'http://komis.staszowski.pl/'} target={'_blank'} tabIndex={0} className={styles.menuLink}>
                    {menu.dealer}
                  </a>
                </li>

              </ul>
            </Fade>
          </div>

          <div className={styles.beam}>
            <Fade right>
              <ul className={styles.beamList}>

                {menu.informations.map((service, index) => (
                  <li key={index} className={styles.beamItem}>
                    {service.name}
                    <a href={`tel:${service.tel.split(' ').join('')}`} className={styles.beamLink}>
                      {service.tel}
                    </a>
                  </li>
                ))}

              </ul>
            </Fade>
          </div>

          <button className={styles.hamburger} onClick={handleHamburgerClick}>
            <img src="/assets/hamburger-icon.svg" alt="Otwórz menu"/>
          </button>
        </div>
      </div>
    </div>

    <MobileNav active={isMobileNavActive} closeClick={handleHamburgerCloseClick}/>
  </>;
};

export default Navbar;
