import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Parallax } from 'react-scroll-parallax';
import Carousel from '../../carousel/carousel.js';
import content from '../../../../content/sections/handling.yml';

import styles from './handling.module.scss';

const Handling = () => {
  return <div className={styles.handling}>
    <div className={`container`}>
      <div className={`row no-gutters`}>
        <div className={`col-12 col-xl-5`}>
          <div className={styles.imageWrapper}>
            <div className={styles.image}>
              <Parallax y={[-15, 5]}>
                <Carousel slides={content.slides}/>
              </Parallax>
            </div>
          </div>
        </div>
        <div className={`col-12 col-xl-6 offset-xl-1`}>
          <div className={`section`}>
            <h2 className={styles.title}>
              {content.title}
            </h2>
            <div className={styles.text}>
              <ReactMarkdown>
                {content.text}
              </ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>;
};

export default Handling;
